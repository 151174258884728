import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Health</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Health </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <h1 className="playfair text-black text-xl md:text-3xl">
                  About Virgo
                </h1>
                <br/>
                <span>
                  ● The Sanskrit/Vedic name of Virgo: Kanya <br /> ● The meaning
                  of Virgo: The Virgin <br /> ● Type: Earth - Mutable - Negative <br/>
                </span>
                  <br/>
                <h1 className="playfair text-black text-xl md:text-3xl">
                  Know more about the Virgo sign
                </h1>
                <br/>
                <span className="mb-4">
                  The constellation Virgo is a diminutive one, just above the
                  waistline of the constellation Libra. <br />
                  It is a hardworking and convenient sign and one of the most
                  helpful signs of the zodiac. It is also one of the least
                  extroverted signs, although it is very protective of its
                  friends and loved ones. It is an excellent sign for any
                  student and is suitable for anyone who values practicality and
                  hard work.
                  <br /> <br />
                  Virgo is a practical sign, not merely that it's concerned with
                  getting things done. It's also a traditionally mutable sign,
                  meaning that you can achieve a lot in it – provided you keep
                  an open mind. You aren't afraid to try new things. Virgo is
                  the sign of the doer, the thinker, and the planner. It's
                  concerned with service and duty. It tends to over-achieve to
                  live up to expectations and responsibilities.
                  <br /> <br />
                  Virgo is a practical sign – it's about what you can do, not
                  about what you want to or wish you could. This is an excellent
                  quality in character because it helps you plan, think ahead,
                  and make the best decisions based on your current
                  circumstances. The downside is that you can get bogged down in
                  details and lose sight of the big picture.<br/>
                  <br></br>
                    Ruled by the Symbol of the virgin, Virgo is all about
                    modesty, responsibility, and hard work.<br/>
                  <br/>
                  <p className="playfair text-black text-xl md:text-3xl">
                    How do you recognize Virgo?<br/>
                  </p>
                  <br/>
                  One of the easiest ways to identify a Virgo is its appearance.
                  Most Virgo's are efficient, and you can often tell that they
                  come from a stable, middle-class background. <br />● They tend
                  to be average in height, with average builds. <br />
                  ● They are often described as very good-looking, but no one
                  would ever mistake them for being extroverted.
                  <br />
                  <br />
                  Virgos are known for their perfectionism, attention to detail,
                  curating environments, deliberation, and multitasking ability.
                  They are known for their compassion, their empathy, their
                  ability to listen, their ability to care, and their ability to
                  relate.
                  <br />
                  <br />
                  Virgos are known for their ability to plan, their ability to
                  strategize, their ability to observe, their ability to
                  analyze, their ability to understand, and their ability to
                  work hard. Virgos are known for their ability to think outside
                  the box, their ability to learn from others, their ability to
                  adapt, and their ability to be self-sufficient.
                  <br />
                  <br />
                  Virgo is a sign that's all about balance. They're the
                  self-sacrificers, the caretakers, the people who go to bed
                  with one eye open and sleep like the dead. They're the fixers,
                  the planners, the analyzers, the worriers, the doers, the
                  voters, the fixers, the people who know things, the people who
                  ask questions, and the people who don't know anything about
                  anything.
                  <br />
                  <br />
                  However, one characteristic that sets Virgos apart from other
                  signs is their desire for a harmonious, orderly, and
                  well-planned environment. This desire for structure and
                  organization comes from their need for security and safety as
                  children. They also tend to have many friends, probably
                  because they are very generous and will go out of their way to
                  help others.
                  <br />
                  <br />
                  Virgo women are Quiet and soft. They're perfect — and they're
                  the most organized of the zodiac, with their neat filing
                  systems and orderliness. Virgo women are quiet with beautiful
                  and soft eyes. The perfectionists of the zodiac wheel are more
                  likely to work late at the office rather than being gregarious
                  at a cocktail party.
                  <br />
                  <br />
                  You may have heard the phrase "Virgo women are bossy" before.
                  But is that the case? Sure, Virgo women are highly independent
                  and good at making decisions, but is that all there is? Maybe
                  there's more to their independent nature than meets the eye.
                  <br />
                  <br />
                  It is primarily because Virgo women are extremely hard to
                  distinguish from one another, even though they all have their
                  unique characteristics. It's a complex sign, defined by its
                  share of different personality traits rather than a specific
                  set of behaviors or qualities. It is only through close
                  observation and familiarity with Virgo's personality traits
                  that you can begin to identify this sign.
                  <br />
                  <br />
                  Despite being a reserved and notoriously private sign, Virgo
                  women have a lot to offer the right person. They are
                  hardworking, self-motivated, and down-to-earth. Most
                  importantly, they are loyal friends and partners. Because of
                  this, it can be easy to overlook Virgo's essential personality
                  traits in favor of appearances.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    All About Virgo{" "}
                  </p>
                  <br/>
                  <p className="font-bold inline-block text-orange-500">Date Range:</p> August 23 - September 22
                  <br />
                  <p className="font-bold inline-block text-orange-500">Domicile:</p> Mercury
                  <br />
                  <p className="font-bold inline-block text-orange-500">Detriment:</p> Neptune
                  <br />
                  <p className="font-bold inline-block text-orange-500">Exaltation:</p> Mercury
                  <br />
                  <p className="font-bold inline-block text-orange-500">Fall:</p> Venus
                  <br />
                  <p className="font-bold inline-block text-orange-500">Element:</p> Earth
                  <br />
                  <p className="font-bold inline-block text-orange-500">Quality:</p> Mutable
                  <br />
                  <p className="font-bold inline-block text-orange-500">Symbol:</p> The Virgin
                  <br />
                  <p className="font-bold inline-block text-orange-500">Tarot card:</p> The Hermit
                  <br />
                  <p className="font-bold inline-block text-orange-500">Fellow signs:</p> Taurus, Capricorn
                  <br />
                  <p className="font-bold inline-block text-orange-500">Compatible signs:</p> Taurus, Gemini, Cancer, Sagittarius,
                  Capricorn
                  <br />
                  <p className="font-bold inline-block text-orange-500">Most Compatible Signs:</p> Cancer
                  <br />
                  <p className="font-bold inline-block text-orange-500">Opposite Signs:</p> Pisces
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    The Symbol denotes Matters about nutrition, wellness,
                    attire, hygiene, and fitness.
                  </p>
                  <br />
                  <p className="font-bold inline-block text-orange-500">Characteristics:</p> Earthy, mutable, feminine, cold
                  <br />
                  <p className="font-bold inline-block text-orange-500">Noteworthy qualities:</p>  Thoughtfulness, sharpness, efficiency,
                  carefulness, intellect, domesticity, tactfulness,
                  perseverance, action orientation, intuitiveness.
                  <br />
                  <p className="font-bold inline-block text-orange-500">Undesirable personality traits:</p> Egoism, crankiness,
                  nervousness, secretiveness, mistrust, apathy,
                  unresponsiveness, instability, indecision, short-tempered
                  nature, cowardice, calculative approach.
                  <br />
                  <p className="font-bold inline-block text-orange-500">Favorable things:</p> Laptops, journals, long showers with
                  aromatherapy soaps, outdoor concerts, childhood friends,
                  Trivial Pursuit
                  <br />
                  <p className="font-bold inline-block text-orange-500">Things they don’t like:</p> Lazy people, dive bars, spicy food,
                  leaving home, toothpaste squeezed from the top of the tube.
                  <br />
                  <p className="font-bold inline-block text-orange-500">Birthstone:</p> Emerald
                  <br />
                  <p className="font-bold inline-block text-orange-500">Lucky stones: </p> Peridot
                  <br />
                  <p className="font-bold inline-block text-orange-500">Lucky Metals:</p> Silver, Mercury
                  <br />
                  <p className="font-bold inline-block text-orange-500">Lucky day: </p> Wednesday
                  <br />
                  <p className="font-bold inline-block text-orange-500">Lucky number: </p> 5, 14, 15, 23, 32, 41, 50
                  <br />
                  <p className="font-bold inline-block text-orange-500">Birth Colours:</p> Grey, Beige, Pale - Yellow
                  <br />
                  <p className="font-bold inline-block text-orange-500">Deepest thought:</p> To be helpful and fruitful
                  <br />
                  <p className="font-bold inline-block text-orange-500">Lifelong wish:</p> Always a hero
                  <br />
                  <p className="font-bold inline-block text-orange-500">Mantra for life:</p> Always work for better future
                  <br />
                  <p className="font-bold inline-block text-orange-500">The quality most needed for balance:</p> A more comprehensive
                  perspective
                  <br />
                  </span>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
